.watermark {
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(../assents/watermark/watermark.svg);
  background-repeat: no-repeat;
  height: 120px;
  padding: 49px;
  h1{
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    size: 48px;
    color: rgb(0, 0, 1);
  }
}
