.Employer {
  width: 100%;
  height: auto;
  padding-top: 200px;
  padding-bottom: 100px;
  .employer__img {
    background-image: url(../../assents/employer_img/employerbg.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    height: 735px;
    z-index: 0;
    padding-top: 120px;
    padding-left:20px ;
    display: flex;
    justify-content: flex-start;
    position: relative;
    img {
      position: absolute;
      left: 1%;
      top: 1%;
    }
    h1 {
      font-family: "Ropa-sans", sans-serif;
      font-weight: 400;
      font-size: 36px;
      text-align: start;
      width: 75%;
      b{
        color: #ffffff;
      }
    }
  }

}
@media(max-width:425px){
  h1{
    font-size: 18px !important;
  }

}
@media(max-width:555px){
  h1{
    font-size: 18px !important;
  }

}
