.changeLang{

    select{
        border: 1px color rgba(0, 74, 173, 1) ;
        color:   rgba(0, 74, 173, 1); ; 
        padding: 12px 16px; 
        font-size: 14px;
        text-decoration: none; 
        display: block; 
        border-radius: 8px; 
  
        option{
            color:   rgba(0, 74, 173, 1); ; 
            padding: 12px 16px; 
            font-size: 14px;
            text-decoration: none; 
            display: block; 
            border-radius: 8px; 
            background-image: url(../../assents/header_img/FlagRussia.svg);
        }
    }
}