.Ram {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: auto;
  background: rgba(0, 74, 173, 1);
  overflow: hidden;
  padding: 20px;
  text-align: center;
  p,
  h1 {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 24px;
    color: white;
    width: 87%;
  }
}
