.Ramka {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
  border: 1px solid rgba(26, 122, 255, 1);
  border-radius: 10px;
  p {
    font-family: "Poppins", sans-serif;
    font-size: 24px;
    font-weight: 400;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: center;
    color: rgba(0, 0, 0, 1);
  }
}
