.ContactForm {
  width: 100%;
  height: auto;
  background-color: rgba(214, 231, 255, 1);
  padding-top: 64px;
  padding-bottom: 90px;
  .dropdown {
    position: relative;
    display: inline-block;
    width: 30%;
    .dropdown:hover .contentdown {
      display: block;
    }
    select {
      width: 100%;
      padding: 10px;
      margin: 5px 0;
      box-sizing: border-box;
      border: 1px solid #ccc;
      border-radius: 5px;
      height: 60px;
      width: 100%;
      cursor: pointer;
      select:focus {
        border-color: #3498db;
        outline: none;
      }
    }
  }
  .Paragraph,
  .message {
    display: flex;
    justify-content: center;
    text-align: center;
    border-radius: 6px;
    padding: 27px;
    width: 30%;
    height: 100px;
    background-color: transparent;
    border: none;
    color: white;
    box-shadow: none;
    h1{
      font-weight: 300;
    }
  }
  form {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 15px;
    .contact__agree {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 30%;
      border-radius: 6px;
      background: rgba(255, 255, 255, 1);
      box-shadow: -16px 16px 56px -8px rgba(145, 158, 171, 0.16);
      border-radius: 8px;
      height: 160px;
      padding: 20px;
      gap: 10px;
      input {
        height: 25px;
        width: 5%;
      }
    }
    input {
      box-shadow: -16px 16px 56px -8px rgba(145, 158, 171, 0.16);
      padding: 10px 10px 10px 20px;
      border-radius: 6px;
      background: rgba(255, 255, 255, 1);
      height: 60px;
      width: 30%;
    }
  }
  .form__message {
    display: flex;
    justify-content: center;
    width: 30%;
    height: 250px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    padding: 10px;
    border-radius: 6px;
    background-color: rgba(255, 255, 255, 1);
    input {
      border: 3px solid rgba(0, 74, 173, 1);
      height: 200px;
      width: 95%;
    }
  }
  .btn__form {
    padding: 15px;
    background-color: rgba(0, 74, 173, 1);
    color: white;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 20px;
    width: 15%;
  }
  p {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 16px;
    color: rgba(0, 74, 173, 1);
  }
  h1 {
    font-family: "Poppins", sans-serif;
    font-size: 26px;
    font-weight: 600;
    line-height: 39px;
    color: rgba(0, 74, 173, 1);
  }
  .error {
    color: red;
  }
  .successMessage{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    .succesfull{
      display: flex;
      align-items: center;
      gap: 15px;
      border: 1px solid rgba(0, 74, 173, 1) ;
      padding: 20px;
    }
    button{
      padding: 15px;
      background-color: rgba(0, 74, 173, 1);
      color: white;
      font-family: "Poppins", sans-serif;
      font-weight: 400;
      font-size: 15px;
      width: 15%;
    }
  }

  
  @media (max-width: 1320px) {
    .dropdown{
      width: 60%;
    }
  
    .Paragraph,
    .message {
      width: 60%;
    }
    form {
      input {
        width: 60%;
      }
    }
    .form__message {
      width: 60%;
    }
    .btn__form {
      width: 20%;
    }
    .contact__agree {
      width: 60% !important;
    }
  }
  @media (max-width: 768px) {
    .btn__form {
      width: 25%;
    }
    .successMessage{
      button{
        width: 25%;
      }
    }
  }
  @media (max-width: 425px) {
    .dropdown{
      width: 100%;
    }
  
    .Paragraph,
    .message {
      width: 100%;
    }
    form {
      input {
        width: 100%;
      }
    }
    .form__message {
      width: 100%;
    }
    .btn__form {
      width: 35%;
    }
    .contact__agree {
      width: 100% !important;
    }
    .successMessage{
      button{
        width: 20% !important;
      }
    }
  
  }
  @media (max-width: 555px) {
    .dropdown{
      width: 100%;
    }
  
    .Paragraph,
    .message {
      width: 100%;
    }
    form {
      input {
        width: 100%;
      }
    }
    .form__message {
      width: 100%;
    }
    .btn__form {
      width: 50%;
    }
    .contact__agree {
      width: 100% !important;
    }
    .successMessage{
      button{
        width: 55% !important;
      }
    }
  }
  @media (max-width: 325px){
    .contact__agree{
      p{
        font-size: 13px !important;
      }
    }
    .successMessage{
      button{
        width: 65% !important;
      }
    }
  }

}





