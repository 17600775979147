.Ram {
  width: 100%;
  height: auto;
  background: rgba(0, 74, 173, 1);
  .ram2 {
    border-bottom: 5px solid white;
    border-right: 5px solid white;
    width: 100%;
    padding: 20px;
    p {
      font-family: "Poppins", sans-serif;
      font-weight: 500;
      font-size: 24px;
      color: white;
      width: 85%;
    }
  }
}
