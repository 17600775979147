.OurTeamCard {
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 303px;
  align-items: center;
  text-align: center;
  width: 100%;
  img {
    border: none;
  }
  p {
    font-family: "Ropa Sans", sans-serif;
    font-size: 16px;
    color: rgba(75, 75, 75, 0.7);
    font-weight: 300;
  }
  h1 {
    font-family: "Ropa Sans", sans-serif;
    font-size: 22px;
    color: rgba(0, 0, 1);
  }
}
