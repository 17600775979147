.RamButton {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 24px;
  width: 100%;
  height: 80px;
  .text {
    border: 1px solid rgba(0, 74, 173, 1);
    padding: 10px;
    border-radius: 5px;
    width: 50%;
  }
  a {
    font-size: 20px;
    font-weight: 400;
    text-align: center;
    color: rgba(0, 74, 173, 1);
  }
  button {
    padding: 20px;
    background-color: rgba(0, 74, 173, 1);
    color: white;
    font-size: 20px;
    font-weight: 400;  
  a{
    color: white;
  }
  }
}
@media (max-width: 768px) {
  .RamButton {
    width: 90%;
    .text {
display: none !important;
    }
  }
}
@media (max-width: 555px) {
  .RamButton {
    width: 90%;
    .text {
display: none !important;
    }
  }
}
@media (max-width: 425px) {
  .RamButton {
    width: 90%;
    .text {
display: none !important;
    }
  }
}