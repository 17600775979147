.header{
    display: flex;
    align-items: center;
    width: 100%;
    height: 160px;
    border-bottom: 3px solid  rgba(0, 74, 173, 1) ;
    position: fixed;
    margin-bottom: 200px;
    background-color: #ffffff;
    z-index: 150;
   .header_center__content{
    display: flex;
    align-items: center;
    gap: 100px;
   }
  
   .content{
    display: flex;
    align-items: center;
    width: 100%;
    gap: 59px;
   }
  .dropdown { 
    position: relative; 
    display: inline-block; 
    background-color: transparent;
  } 
  .contentdown { 
    display: none; 
    position: absolute; 
    background-color: white; 
    width: 160px; 
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2); 
    z-index: 1; 
    border-radius: 8px; 
    a { 
        color:  
        rgba(0, 74, 173, 1); ; 
        padding: 12px 16px; 
        font-size: 14px;
        text-decoration: none; 
        display: block; 
        border-radius: 8px; 
    } 

  } 
   
  .dropdown:hover .contentdown { 
    display: block; 
  } 
   
  .dropdown:hover .dropbtn { 
    background: rgba(0, 74, 173, 1); 
    color: #FFFFFF; 
    transition: 0.5s; 
    padding: 4px 14px; 
  }
  .dropbtn{
    background-color: transparent;
  }
.content__adaptive {
  display: none; 
  cursor: pointer;
}
.menu{
  display: none;
}
.drop_down { 
  position: relative; 
  display: inline-block; 
  background-color: transparent;
} 
.contentdown { 
  display: none; 
  position: absolute; 
  background-color: white; 
  width: 160px; 
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2); 
  z-index: 1; 
  border-radius: 8px; 
  a { 
      color:  
      rgba(0, 74, 173, 1); ; 
      padding: 12px 16px; 
      font-size: 14px;
      text-decoration: none; 
      display: block; 
      border-radius: 8px; 
  } 

} 
.drop_down:hover .content_down { 
  display: block; 
} 
 
.drop_down:hover .drop_btn { 
  background: rgba(0, 74, 173, 1); 
  color: #FFFFFF; 
  transition: 0.5s; 
  padding: 4px 14px; 
}
.drop_btn{
  background-color: transparent;
}



@media screen and (max-width: 926px) {
  .content {
    display: none; 
  }
  .content__adaptive {
    display: block; 
    gap: 10%;
  }
  .menu{
    display: block;
  }
  .translation{
    display: none;
  }
  .header_center__content{
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    gap: 40% !important;
    .logo{
      width: 50% !important;
      img{
        width:90%
      }
      
    }
    .menu{
      display: block;
    }
   }
  
}
@media screen and (max-width: 425px){
  .header_center__content{
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    gap: 40% !important;
    .logo{
      width: 50% !important;
      img{
        width:90%
      }
      
    }
    .menu{
      display: block;
    }
    .translation{
      display: none;
    }
   }
}
@media  (max-width: 555px){
  .header_center__content{
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    gap: 40% !important;
    .logo{
      width: 50% !important;
      img{
        width:90%
      }
      
    }
    .menu{
      display: block;
    }
    .translation{
      display: none;
    }
   }
}
}
