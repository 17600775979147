.Footer {
  width: 100%;
  height: auto;
  border: 3px solid rgba(0, 74, 173, 1);
  .dropdown { 
    position: relative; 
    display: inline-block; 
    background-color: transparent;
  } 
  .contentdown { 
    display: none; 
    position: absolute; 
    background-color: white; 
    width: 160px; 
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2); 
    z-index: 1; 
    border-radius: 8px; 
    a { 
      
        padding: 12px 16px; 
        font-size: 14px;
        text-decoration: none; 
        display: block; 
        border-radius: 8px; 
    } 

  } 
   
  .dropdown:hover .contentdown { 
    display: block; 
  } 
   
  .dropdown:hover .dropbtn { 
    background: rgba(0, 74, 173, 1); 
    color: #FFFFFF; 
    transition: 0.1s; 
    padding: 6px 16px; 
  }
  .dropbtn{
    background-color: transparent;
  }
  .Footer__column {
    display: flex;
    flex-direction: column;
    .center {
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 24px;
    }
    .icon {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
    }
    .impersssum,
    .Footer__rules,
    .text__link {
      display: flex;
      gap: 24px;
    }
    .center__info {
      display: flex;
      flex-direction: column;
      gap: 26px;
      align-items: center;
    }
    .Footer__rules {
      p, a {
        font-family: "Poppins",sans-serif;
        font-size: 18px;
        font-weight: 400;
        color: rgba(156, 156, 156, 1);
      }
    }
  }
  .social{
    display: flex;
    gap: 32px;
  }
  .text__link{
    display: flex;
    gap: 20px;
    align-items: center;
  }
  @media  (max-width: 425px) {
    .Footer__rules{
        display: none !important;
        p,a{
          font-size: 14px !important;
        }
    }
    .dropdown{
      display: none;
    }
 
}
@media  (max-width: 555px) {
  .Footer__rules{
      display: none !important;
      p,a{
        font-size: 14px !important;
      }    
  }
  .dropdown{
    display: none;
  }
}
}
