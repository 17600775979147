.AboutUs {
  height: auto;
  width: 100%;
  .Form{
    width: 36%;
  }
  @media (max-width: 425px) {
    .AboutUs {
      p {
        font-size: 16px !important;
      }
    }
  }
  @media (max-width: 555px) {
    .AboutUs {
      p {
        font-size: 16px !important;
      }
    }
  }
}
