.Consulting {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: auto;
  padding-top: 64px;
  padding-bottom: 90px;
  .Paragraph {
    display: flex;
    flex-direction: column;
    gap: 24px;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .Consulting__flex {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 22px;
    padding-top: 64px;
    margin-bottom: 50px;
    .right {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      gap: 30px;
      width: 39%;
      height: auto;
    }
    .searchers{
      p{
        font-size:24px ;
      }
    }
  }
  .flex {
    display: flex;
    justify-content: space-between;
    gap: 50px;
    align-items: center;
    margin-top: 50px;
  }
  .ram {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    width: 75%;
  }
}
@media (max-width: 1100px) {
  .Consulting {
    height: auto;
    padding-top: 30px;
    .Consulting__flex {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      gap: 32px;
      padding-top: 104px;
      margin-bottom: 50px;
      .right {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 100px;
        width: 100%;
        .searchers {
          width: 80%;
        }
      }
    }
    .flex {
      display: flex;
      flex-direction: column;
    }
    .ram {
      margin-bottom: 30px;
    }
  }
}
@media (max-width: 425px) {
  .Consulting {
    height: auto;
    margin-top: 30px;
    .Consulting__flex {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      gap: 32px;
      padding-top: 104px;
      margin-bottom: 50px;
      .right {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 100px;
        width: 100%;
      }
      .left {
        display: flex;
        justify-content: center;
        img {
          width: 60%;
        }
      }
      .searchers {
        width: 100%;
        img {
          width: 60%;
        }
      }
    }
    .flex {
      display: flex;
      flex-direction: column;
    }
    .ram {
      width: 100% !important;
      p {
        font-size: 15px !important;
      }
    }
  }
}
@media (max-width: 555px) {
  .Consulting {
    height: auto;
    margin-top: 30px;
    .Consulting__flex {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      gap: 32px;
      padding-top: 104px;
      margin-bottom: 50px;
      .right {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 100px;
        width: 100%;
      }
      .left {
        display: flex;
        justify-content: center;
        img {
          width: 60%;
        }
      }
      .searchers {
        width: 100%;
        img {
          width: 60%;
        }
      }
    }
    .flex {
      display: flex;
      flex-direction: column;
    }
    .ram {
      width: 100% !important;
      p {
        font-size: 16px !important;
        font-weight: 600;
      }
    }
  }
}
