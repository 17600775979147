.Card {
  display: flex;
  flex-direction: column;
  gap: 16px;
  .center__card{
    text-align: center;
  }
  .text__card{
    margin-top: 25px;
  }
  p {
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    font-weight: 500;
    color: rgba(0, 74, 173, 1);
    margin-top: 5px;
  }
}
