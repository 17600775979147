.burger_menu {
    position: relative;
    .menu_button {
        cursor: pointer;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 20px;
        height: 12px;
      }
      .bar {
        width: 30px;
        height: 4px;
        background-color: #333;
        transition: 0.4s;
      }
      .menu_button.open .bar:nth-child(1) {
        transform: rotate(-45deg) translate(-6px, 3px);
      }
      
      .menu_button.open .bar:nth-child(2) {
        opacity: 0;
      }
      
      .menu_button.open .bar:nth-child(3) {
        transform: rotate(45deg) translate(-6px, -3px);
      }
      
      .menu_items {
        position: absolute;
        background-color: transparent;
      }
      .menu_items ul {
        display: flex;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        list-style: none;
        margin-top: 20px;
        position: absolute;
        background: white;
        width: 50vw;
        text-align: start;
        padding-left: 20px;
        background-color: white; 
        box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
         border-radius: 10px; 
         padding: 20px;
    
      }
      
      .menu_items li {
        color:   rgba(0, 74, 173, 1);;
        padding: 12px 16px; 
        font-size: 10px;
        text-decoration: none; 
        display: block; 
        border-radius: 8px; 
        :hover{
          color:  
          rgb(6, 64, 139); ; 
        }
      }
      
      .menu_button.open + .menu_items {
        display: block;
      }
      
  }
  
  