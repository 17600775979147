.guarantee {
  width: 100%;
  background: #e8eff8;
  height: auto;
  padding-top: 64px;
  padding-bottom: 90px;
  .Paragraph {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .guarantee__flex {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding-top: 76px;
    gap: 15%;
    margin-bottom: 50px;
    .guarantee__right {
      display: flex;
      flex-direction: column;
      gap: 54px;
      align-items: flex-start;
    }
    .guarantee__left {
      width: 100%;
    }
  }
}
@media screen and (max-width: 1200px ) {
  .guarantee {
    height: auto;
    .guarantee__flex {
      display: flex;
      justify-content: center;
      gap: 10%;
      .guarantee__right {
        width: 50%;
        p {
          font-size: 14px;
        }
      }
    }
  }
  @media  (max-width: 425px){
    .guarantee__flex{
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 45px !important;
      .guarantee__right {
        display: flex;
        flex-direction: column;
        gap: 54px;
        align-items: flex-start;
        width: 100% !important;
        p{
          font-size: 14px ;
        }
        img{
          display: none;
        }
      }
      .guarantee__left{
        width: 100% !important;
      }
    }
  }
  @media  (max-width: 556px){
    .guarantee__flex{
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 45px !important;
      .guarantee__right {
        display: flex;
        flex-direction: column;
        gap: 54px;
        align-items: flex-start;
        width: 100% !important;
        p{
          font-size: 14px ;
        }
        img{
          display: none;
        }
      }
      .guarantee__left{
        width: 100% !important;
      }
    }
  }
  @media  (max-width: 1000px){
    .guarantee__flex{
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 45px !important;
      .guarantee__right {
        display: flex;
        flex-direction: column;
        gap: 54px;
        align-items: flex-start;
        width: 100% !important;
        p{
          font-size: 14px ;
        }
        img{
          display: none;
        }
      }
      .guarantee__left{
        width: 100% !important;
      }
    }
  }
}
