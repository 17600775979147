* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
a {
  color: inherit;
  text-decoration: none;
}

button,
input {
  outline: none;
  border: none;
}


p,
span,
b,
button,
input,
a {
  font-family: "Poppins", sans-serif !important;
}
a {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  &:hover {
    color: rgba(0, 74, 173, 1);
  }
}
.container {
  width: 90%;
  margin: 0 auto;
}
.container2{
  width: 95%;
  margin: 0 auto;
  color: white;
}
button {
  cursor: pointer;
}

@media (max-width: 912px) {
  .container {
    width: 88%;
    margin: 0 auto;
  }
  .container2 {
    width: 88%;
    margin: 0 auto;
  }
}

@media (max-width: 576px) {
  .container {
    width: 90%;
    margin: 0 auto;
  }
  .container2 {
    width: 100%;
    margin: 0 auto;
  }
}
@media (max-width: 425px) {
  .Main{
    p{
      font-size: 11px !important;
     }
  }

}
@media (max-width: 555px) {
  .Main{
    p{
      font-size: 11px !important;
     }
  }
}
@media  (max-width: 1024px){
  .QuestionSheme_QuestionsSheme__Mk09i {
    width: 100% !important;
  }
}
@media (max-width: 425px) {
  .OurTeam_OurTeam__iHJb4 .OurTeam_our__flex__AQ8tz{
    display: flex;
    flex-direction: column !important;
  }

}
@media (max-width: 555px) {
  .AboutUs{
    p{
      font-size: 16px !important;
     }
  }
}

