.ServicesEmployer {
  display: flex;
  flex-direction: column;
  gap: 100px;
  padding-top: 100px;
  padding-bottom: 100px;
  width: 100%;
  height: auto;
  .Paragraph {
    display: flex;
    justify-content: center;
    text-align: center;
    h1 {
      font-family: "Poppins", sans-serif;
      font-weight: 500;
      size: 48px;
      color: rgb(0, 0, 1);
      width: 50%;
    }
  }
  .ServicesEmp__width {
    width: 60%;
  }
  .Services__Cheme {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
  }
}
@media (max-width: 768px) {
  .ServicesEmployer {
    .Paragraph {
      width: 100%;
      h1 {
        width: 100%;
      }
    }
    .ServicesEmp__width {
      width: 100%;
    }
    .Services__Cheme {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 100%;
    }
  }
  @media (max-width: 425px) {
    .ServicesEmployer {
      .Paragraph {
        width: 100%;
        h1 {
          width: 100%;
        }
      }
      .ServicesEmp__width {
        width: 100%;
        p {
          font-size: 14px !important;
        }
      }
      .Services__Cheme {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        gap: 80px p {
          font-size: 14px !important;
        }
      }
    }
  }
  @media (max-width: 555px) {
    .ServicesEmployer {
      .Paragraph {
        width: 100%;
        h1 {
          width: 100%;
        }
      }
      .ServicesEmp__width {
        width: 100%;
        p {
          font-size: 14px !important;
        }
      }
      .Services__Cheme {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        gap: 80px;
      }
    }
  }
}
