.Contact{
    width: 100%;
    padding-top: 210px;
    height: auto;
    .contact__img{
        background-size: cover;
        background-repeat: no-repeat;
        .main__img {
            img {
              width: 100%;
            }
          }
   

    }
}