.EmployerEurope {
  display: flex;
  flex-direction: column;
  gap: 34px;
  width: 100%;
  height: auto;
  margin-top: 90px;
  .Paragraph {
    display: flex;
    flex-direction: column;
    gap: 24px;
    justify-content: center;
    align-items: center;
  }
  .EmployerEurope__bread {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 24px;
    align-items: center;
    .top {
      width: 80%;
    }
    .under {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      gap: 50px;
      .under__left {
        width: 59%;
      }

    }
  }
  @media (max-width: 1200px){
    .EmployerEurope__bread {
      display: flex;
      justify-content: center;
      flex-direction: column;
      gap: 24px;
      align-items: center;
      .top {
        width: 80%;
      }
      .under {
        flex-direction: column;  
        align-items: center;
        justify-content: center;
        .under__left {
          width: 100%;
        }
      
      }
  }
}
@media (max-width:768px){
  .EmployerEurope__bread {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 24px;
    align-items: center;
    .top {
      width: 80%;
      p{
        font-size: 14px !important;
      }
    }
 
    
  }
}
@media (max-width:425px){
  .EmployerEurope__bread {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 24px;
    align-items: center;
    .top {
      width: 100%;
      p{
        font-size: 14px !important;
      }
    }
    .under {
      flex-direction: column;  
      align-items: center;
      justify-content: center;
      img{
        width: 90%;
      }
  
        
      
    }
 
    
  }
}
}
